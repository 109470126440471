import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { Context } from 'frontend-container/components/Menu/components/Context';
import { handleMenuItemRedirect } from 'frontend-container/components/Menu/components/Item/handleMenuItemRedirect';
import { useMenuShortcuts } from 'frontend-container/components/Menu/components/Item/useMenuShortcuts';
import { MegaMenuFavoritesGroup } from 'frontend-container/components/Menu/components/MegaMenu/MegaMenuFavoritesGroup';
import { MegaMenuItem } from 'frontend-container/components/Menu/components/MegaMenu/MegaMenuItem';
import {
  RightMenuItem,
  useRightColumnConfig,
} from 'frontend-container/components/Menu/components/MegaMenu/useRightColumnConfig';
import { isPropertyContextVisible } from 'frontend-container/components/Menu/components/PropertyContext';
import { userPreferencesService } from 'frontend-container/components/Menu/components/User/UserPreferences/service';
import { getInitialValues } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/values/getInitialValues';
import { useFavoritesContext } from 'frontend-container/components/Menu/store/favorites/context';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';
import { getSortedMenuItems } from 'frontend-container/components/Menu/utils/getSortedMenuItems';

import { LoginService } from '@ac/library-utils/dist/services';
import { useContainerToast } from '@ac/react-infrastructure';
import { DividerColor, DividerSpacing, MenuSlot } from '@ac/web-components';

interface Props {
  isVisible: boolean;
  selectedCro: Context | undefined;
  selectedProfileCenter: Context | undefined;
  selectedProperty: Context | undefined;
  onCloseCallback?: () => void;
  allAccessConfiguration: AllAccessConfiguration | undefined;
  mainMenuItems: MenuElement[];
}

export const MegaMenu = ({
  isVisible,
  selectedCro,
  selectedProfileCenter,
  selectedProperty,
  onCloseCallback,
  allAccessConfiguration,
  mainMenuItems,
}: Props): JSX.Element | null => {
  const [isFavoriteOnly, setIsFavoriteOnly] = useState<boolean>(() =>
    userPreferencesService.shouldShowFavoritesMenuOnly()
  );
  const [isDirty, setIsDirty] = useState(false);
  const [shouldBeGroupedWithNext, setShouldBeGroupedWithNext] =
    useState<boolean>(false);
  const [shouldFavoriteButtonBeVisible, setShouldFavoriteButtonBeVisible] =
    useState<boolean>(false);

  const { favoritesItemsIds, toggleFavorite } = useFavoritesContext();

  const toggleFavoriteAndSetIsDrity = useCallback((id: string) => {
    toggleFavorite(id);
    setIsDirty(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isEditMode, setIsEditMode] = useState(false);
  const { showError } = useContainerToast();
  const { t } = useTranslation();

  const alignFavoritesWithFirstMenuItemsThreshold = 1;

  const onCloseAndSaveCallback = async (): Promise<void> => {
    onCloseCallback?.();

    if (isDirty) {
      const allPreferences = getInitialValues();

      const newPreferences = {
        ...allPreferences,
        mainMenuSettings: {
          favoriteMenuItems: favoritesItemsIds,
          showFavoriteOnly: isFavoriteOnly,
        },
      };

      const hasSucceed = await userPreferencesService.saveUserPreferences(
        newPreferences,
        false
      );

      if (!hasSucceed) {
        showError(t('MENU.MAIN_MENU.ERRORS.NO_FAVORITES_ADDED'));
      }

      setIsDirty(false);
    }
  };

  const isMac = navigator.appVersion.indexOf('Mac') !== -1;

  const sortedItems = useMemo((): MenuElement[][] => {
    return getSortedMenuItems(mainMenuItems);
  }, [mainMenuItems]);

  const handleShortcut = async (link: string): Promise<void> => {
    const menuElement = mainMenuItems.find((element) =>
      element.items.find((item) => item.link === link)
    );
    await handleMenuItemRedirect(link, menuElement?.items);
  };

  useMenuShortcuts(false, handleShortcut);

  const toggleManageMode = (): void => {
    setIsEditMode((prevValue) => !prevValue);
  };

  const toggleShowFavoriteOnly = async (): Promise<void> => {
    const allPreferences = getInitialValues();

    setIsFavoriteOnly((prevValue) => !prevValue);

    const newPreferences = {
      ...allPreferences,
      mainMenuSettings: {
        favoriteMenuItems: favoritesItemsIds,
        showFavoriteOnly: !isFavoriteOnly,
      },
    };

    const hasSucceed = await userPreferencesService.saveUserPreferences(
      newPreferences
    );

    if (!hasSucceed) {
      showError(t('MENU.MAIN_MENU.ERRORS.SINGLE_MODE_SWITCH'));
    }
  };

  const rightMenuItems = useRightColumnConfig(
    allAccessConfiguration,
    selectedCro,
    selectedProfileCenter
  );

  useEffect(() => {
    const itemsMap = new Map<string, MenuElementItem | RightMenuItem>();
    sortedItems.flat().forEach((menu) => {
      menu.items.forEach((item) => {
        const id = `${menu.id}-${item.link}`;
        if (favoritesItemsIds.includes(id)) {
          itemsMap.set(id, item);
        }
      });
    });

    rightMenuItems.forEach((menu) => {
      if (menu.menuElements) {
        const menuId = menu.menuElements[0].id;

        menu.menuElements[0].items.forEach((item) => {
          const id = `${menuId}-${item.link}`;
          if (favoritesItemsIds.includes(id)) {
            itemsMap.set(id, item);
          }
        });
      }
    });

    const shouldFavButtonBeVisible =
      itemsMap.size >= alignFavoritesWithFirstMenuItemsThreshold;
    const shouldBeGrouped =
      itemsMap.size <= alignFavoritesWithFirstMenuItemsThreshold;

    setShouldBeGroupedWithNext(shouldBeGrouped);
    setShouldFavoriteButtonBeVisible(shouldFavButtonBeVisible);
  }, [sortedItems, rightMenuItems, favoritesItemsIds]);

  return !isVisible ? null : (
    <ac-menu
      isVisible={isVisible}
      onOutsideClickCallback={onCloseAndSaveCallback}
      isEditMode={isEditMode}
      isStandalone={isFavoriteOnly}
      key={`menu-${String(isFavoriteOnly)}-${String(shouldBeGroupedWithNext)}`}
    >
      {!isPropertyContextVisible() && (
        <ac-menu-group-header
          slot={MenuSlot.leftTitle}
          headerTitle={selectedProperty?.name}
          headerBadge={selectedProperty?.code}
        />
      )}
      <ac-slot-container slot={MenuSlot.left}>
        {(!shouldBeGroupedWithNext || isFavoriteOnly) &&
          !LoginService.isSuperUser() && (
            <ac-menu-group-favorite
              slot={MenuSlot.left}
              isStandalone={isFavoriteOnly}
              onClickCallback={toggleShowFavoriteOnly}
            >
              <MegaMenuFavoritesGroup
                isMac={isMac}
                favoritesItemsIds={favoritesItemsIds}
                sortedItems={sortedItems}
                rightMenuItems={rightMenuItems}
                isEditMode={isEditMode}
                onCloseAndSaveCallback={onCloseAndSaveCallback}
                toggleManageMode={toggleManageMode}
                isStandalone={isFavoriteOnly}
              />
            </ac-menu-group-favorite>
          )}
        {!isFavoriteOnly &&
          sortedItems.map((menuItem, index) => (
            <ac-menu-group slot={MenuSlot.left} key={menuItem[0].id}>
              {shouldBeGroupedWithNext && index === 0 && (
                <MegaMenuFavoritesGroup
                  isMac={isMac}
                  favoritesItemsIds={favoritesItemsIds}
                  sortedItems={sortedItems}
                  rightMenuItems={rightMenuItems}
                  isEditMode={isEditMode}
                  onCloseAndSaveCallback={onCloseAndSaveCallback}
                  toggleManageMode={toggleManageMode}
                  isStandalone={isFavoriteOnly}
                  isBottomButtonVisible={shouldFavoriteButtonBeVisible}
                  toggleShowFavoriteOnly={toggleShowFavoriteOnly}
                />
              )}
              <MegaMenuItem
                isMac={isMac}
                favoritesItemsIds={favoritesItemsIds}
                onCloseCallback={onCloseAndSaveCallback}
                menuElements={menuItem}
                onFavoriteClickCallback={toggleFavoriteAndSetIsDrity}
                isEditMode={isEditMode}
              />
            </ac-menu-group>
          ))}
      </ac-slot-container>

      <ac-slot-container slot={MenuSlot.right}>
        {!isFavoriteOnly && (
          <Fragment>
            {rightMenuItems.map((rightMenuItem, index) => {
              if (rightMenuItem.menuElements) {
                return (
                  <ac-menu-group
                    slot={MenuSlot.right}
                    key={rightMenuItem.menuElements[0].id}
                  >
                    <MegaMenuItem
                      isMac={isMac}
                      favoritesItemsIds={favoritesItemsIds}
                      selectedContextCode={rightMenuItem.selectedContextCode}
                      key={rightMenuItem.menuElements[0].id}
                      onCloseCallback={onCloseAndSaveCallback}
                      withoutHeader={rightMenuItem.withoutHeader}
                      menuElements={rightMenuItem.menuElements}
                      onFavoriteClickCallback={toggleFavoriteAndSetIsDrity}
                      isEditMode={isEditMode}
                    />
                  </ac-menu-group>
                );
              }

              return (
                <ac-box
                  key={index}
                  slot={MenuSlot.right}
                  class="ac-padding-inline-end-md ac-padding-inline-start-xlg"
                >
                  <ac-divider
                    color={DividerColor.gray200}
                    spacing={DividerSpacing.none}
                  />
                </ac-box>
              );
            })}
          </Fragment>
        )}
      </ac-slot-container>
    </ac-menu>
  );
};
