import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextDropdown } from 'frontend-container/components/Menu/components/Context/components/ContextDropdown/ContextDropdown';
import { useSessionContextTypeIds } from 'frontend-container/components/Menu/components/Context/useSessionContextTypeIds';
import {
  getCurrentPropertyIdentifierColor,
  getPropertyContexts,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import { SelectedPropertyInfotipBody } from 'frontend-container/components/Menu/components/SelectedProperty/SelectedPropertyInfotipBody/SelectedPropertyInfotipBody';

import { useSharedInfotipPresenter } from '@ac/react-infrastructure';
import { OpeningBehavior, TargetValueObject } from '@ac/web-components';

import { useBusinessDateTime } from '../Context/components/ContextDropdown/BusinessDate';

interface Props {
  color?: string;
}

const menuId = 'menu-property-info';

export const SelectedProperty = ({ color }: Props): JSX.Element | null => {
  const { propertyId } = useSessionContextTypeIds();

  const context = useMemo(() => {
    const propertyContexts = getPropertyContexts();

    return propertyContexts.find(({ id }) => id === propertyId);
  }, [propertyId]);

  const { createShowHandler } = useSharedInfotipPresenter(
    SelectedPropertyInfotipBody
  );
  const { t } = useTranslation();
  const { date, dateFormat, time } = useBusinessDateTime();

  const onPointerClick = createShowHandler(
    {
      selectedProperty: context,
      businessDate: date,
      propertyTime: time,
    },
    {
      openingBehavior: OpeningBehavior.onClick,
      attachTo: `#${menuId}`,
      targetValue: TargetValueObject.mainMenu,
    }
  );

  return (
    <>
      <ContextDropdown
        id={menuId}
        selectedContext={context}
        businessDate={date}
        dateFormat={dateFormat}
        propertyTime={time}
        color={color ?? getCurrentPropertyIdentifierColor()}
        tooltipContent={t('MENU.CONTEXT.PROPERTY.TOOLTIP')}
        onClick={onPointerClick}
      />
    </>
  );
};
